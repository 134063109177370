<template>
  <div class="login">
    <form class="login-body" @submit.prevent="onSubmit">
      <img class="login_bg" src="@/assets/img/login-bg.png" alt="" />
      <div class="password-login">Masuk via kata sandi</div>
      <div class="login_form">
        <div class="input-block">
          <input
            v-model.trim="phone"
            type="tel"
            autofocus
            placeholder="Masukkan nomor handphone dengan awalan 08"
            maxlength="13"
          />
        </div>
        <div class="input-block">
          <input
            v-model.trim="password"
            maxlength="8"
            type="tel"
            class="password"
            placeholder="Masukkan 8 digit angka password"
          />
        </div>
        <span class="forget-password" @click="forgetPassword"
          >Lupa Password？</span
        >
      </div>
      <button type="button" class="login-btn" @click="onSubmit">
        Login Password
      </button>
      <button type="button" class="login-btn codeLogin" @click="goMessageLogin">
        Masuk dengan SMS
      </button>
      <button
        v-if="isIOS"
        type="button"
        class="login-btn register"
        @click="goRegister"
      >
        Daftar
      </button>
    </form>
  </div>
</template>

<script>
import { Toast } from 'vant'
import request from '@/utils/request'
import { validatePhoneNumber, validatePassword } from '@/utils/validator'
import { isIOS } from '@/utils/detect'

export default {
  name: 'Login',
  data () {
    return {
      phone: '',
      password: '',
      isIOS
    }
  },
  methods: {
    forgetPassword () {
      const { query } = this.$route
      this.$router.push({
        path: '/forgetPassword',
        query
      })
    },
    validate () {
      if (!this.phone) {
        Toast('Masukkan nomor handphone dengan awalan 08')
        return false
      }
      if (!validatePhoneNumber(this.phone)) {
        Toast('Nomor handphone salah')
        return false
      }
      if (!this.password) {
        Toast('Masukkan 8 digit angka password')
        return false
      }
      if (!validatePassword(this.password)) {
        Toast('Password salah')
        return false
      }
      return true
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      const postData = {
        lodrginType: 1,
        usdrerPhone: this.phone,
        usdrerPassword: this.password
      }
      request.post('usdrerLogin', postData)
        .then(res => {
          this.$store.commit('recordInfo', {
            type: 'userInfo',
            value: res
          })
          localStorage.setItem('userInfo', JSON.stringify(res))
          this.addressBook()
          this.$router.push('/home1')
        })
        .finally(() => {
          this.flag = false
        })
    },
    goMessageLogin () {
      const { query } = this.$route
      this.$router.push({
        path: '/messageLogin',
        query
      })
    },
    goRegister () {
      const { query } = this.$route
      this.$router.push({
        path: '/register',
        query
      })
    }
  }
}
</script>

<style scoped lang="less">
.login {
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 0 34px;
  padding-bottom: 30px;
  .login_bg {
    width: 635px;
    height: 331px;
    margin: 0 auto;
    margin-top: 30px;
  }
  .login_form {
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 622px;
    background: url("../assets/img/login_form_bg.png") no-repeat;
    background-size: 100% 100%;
  }
}

.header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 73px 30px 0;

  p {
    font-weight: 600;

    &:first-child {
      font-size: 40px;
      color: #4837bc;
    }

    &:last-child {
      font-size: 32px;
      color: #f9f9f9;
    }
  }
}

.title-block {
  display: flex;
  height: 88px;
  line-height: 88px;

  & > div {
    flex: 1;
    text-align: center;
  }
}

.password-login {
  font-size: 54px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  margin-top: 10px;
}

.message-login {
  border-radius: 10px;
  font-size: 28px;
  font-weight: 600;
  color: #fff;
}

.input-block {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;

  &:nth-child(n + 2) {
    margin-top: 30px;
  }

  input {
    display: block;
    width: 100%;
    line-height: 100px;
    padding-left: 30px;
    border: none;
    border-radius: 10px;
    box-sizing: border-box;
    font-size: 30px;
    color: #b549fe;
    background: none;
    height: 100px;
    background: #cec7e7;
    border-radius: 50px 50px 50px 50px;
    opacity: 1;

    &::placeholder {
      font-size: 24px;
      font-weight: 400;
      color: #b549fe;
    }

    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}

.password {
  -webkit-text-security: disc;
}

.forget-password {
  float: right;
  margin-top: 24px;
  margin-right: 40px;
  text-align: right;
  color: #666666;
  margin-bottom: 106px;
  margin-top: 30px;
  font-size: 28px;
  font-weight: 600;
  color: #9536d8;
}
.login-btn {
  .submit-btn;
  width: 532px;
  margin-top: 28px;
}

.codeLogin,
.register {
  background: url("../assets/img/button_bg2.png") no-repeat;
  background-size: 100% 100%;
}
.footer {
  width: 358px;
  height: 85px;
  margin: 30px auto 25px;
}
</style>
